<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}
</style>
<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <h4 class="mb-5">Supplier Invoice</h4>
    </span>
    <div class="vx-row mt-6">
      <div class="vx-col w-full">
        <vs-button color="primary" class="mr-3 mb-2" @click="approval(1)">Approve</vs-button>
        <vs-button color="warning" class="mr-3 mb-2" @click="approval(0)">Reject</vs-button>
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full input-readonly"
          label="Supplier"
          name="Supplier"
          v-model="vendor"
          readonly
        />
      </div>
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full input-readonly"
          label="PO Code"
          name="PO Code"
          v-model="poCode"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Warehouse"
          name="Warehouse"
          v-model="warehouseName"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Invoice Number"
          name="Invoice Number"
          v-model="table.inv_code"
          placeholder="Invoice Number"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Invoice Date"
          name="Invoice Date"
          v-model="table.inv_date"
          readonly
        />
      </div>
    </div>
    <div v-if="createdDate != 'January 1, 0001'" class="vx-col w-full mb-2">
      <vs-input
        class="w-full input-readonly"
        label="Created Date"
        name="Created Date"
        v-model="createdDate"
        readonly
      />
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Payment Term"
          name="Payment Term"
          v-model="table.payment_term_name"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Bank Name"
          name="Bank Name"
          v-model="table.bank_name"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Tax Return Number"
          name="Tax Return Number"
          v-model="tr_number"
          placeholder="Tax Return Number"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Tax Return Date"
          name="Tax Return Date"
          v-model="tr_date"
          placeholder="Tax Return Date"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2 ml-1">
      <h5>Line Item</h5>
    </div>
    <vs-table stripe border description :sst="true" :data="table.data">
      <template slot="thead">
        <vs-th>Item</vs-th>
        <vs-th>Quantity</vs-th>
        <vs-th>Price</vs-th>
        <vs-th>Discount</vs-th>
        <vs-th>Net</vs-th>
        <vs-th>Delta</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr
          :data="tr"
          :key="indextr"
          v-for="(tr, indextr) in data"
          :class="data[indextr].class"
        >
          <vs-td :data="data[indextr].item_name">
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ data[indextr].item_name }}</span>
            </div>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ data[indextr].item_unit }}</span>
            </div>
          </vs-td>
          <vs-td>
            PO : {{ data[indextr].qty_po }}<br />
            Received : {{ data[indextr].qty_received }}<br />
            Invoice : {{ data[indextr].quantity }}
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              PO : {{ data[indextr].price_po }}<br />
              Invoice : {{ data[indextr].price }}
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              PO : {{ data[indextr].discount_po }}<br />
              Invoice : {{ data[indextr].discount_inv }}
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              PO : {{ data[indextr].net_po }}<br />
              Invoice : {{ data[indextr].net_inv }}
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              Qty : {{ data[indextr].delta_qty }}<br />
              Price : {{ data[indextr].delta_price }}<br />
              Discount : {{ data[indextr].delta_discount }}<br />
              Net : {{ data[indextr].delta_net }}
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <div class="vx-row mb-2 mt-4 ml-1">
      <h5>Charges</h5>
    </div>
    <vs-table stripe border description :sst="true" :data="table.data_charges">
      <template slot="thead">
        <vs-th>Charge Name</vs-th>
        <vs-th>Value</vs-th>
        <vs-th>Tax</vs-th>
        <vs-th>Delta</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{
                data[indextr].charge_name.replace(/-/g, " ")
              }}</span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              PO : {{ data[indextr].value_po }}<br />
              Invoice : {{ data[indextr].value_inv }}
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              PO : {{ data[indextr].tax_po }}<br />
              Invoice : {{ data[indextr].tax_inv }}
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              Value : {{ data[indextr].delta_value }}<br />
              Tax : {{ data[indextr].delta_tax }}
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <div class="vx-row mb-2 ml-1 mt-4">
      <h5>Total</h5>
    </div>
    <vs-table stripe border description :sst="true" :data="table.data_total">
      <template slot="thead">
        <vs-th>Item</vs-th>
        <vs-th>Charge</vs-th>
        <vs-th>Tax</vs-th>
        <vs-th>Discount</vs-th>
        <vs-th>Total</vs-th>
        <vs-th>Delta</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
         <vs-td>
            PO : {{ data[indextr].item_value_po }}<br />
            Invoice : {{ data[indextr].item_value }}
          </vs-td>
          <vs-td>
            PO : {{ data[indextr].charges_value_po }}<br />
            Invoice : {{ data[indextr].charges_value }}
          </vs-td>
          <vs-td>
            PO : {{ data[indextr].tax_value_po }}<br />
            Invoice : {{ data[indextr].tax_value }}
          </vs-td>
          <vs-td>
            PO : {{ data[indextr].discount_value_po }}<br />
            Invoice : {{ data[indextr].discount_value }}
          </vs-td>
          <vs-td>
            PO : {{ data[indextr].total_po_value }}<br />
            Invoice : {{ data[indextr].total_inv_value }}
          </vs-td>
          <vs-td>
            Item : {{ data[indextr].delta_item_value }}<br />
            Charge : {{ data[indextr].delta_charge_value }}<br />
            Tax : {{ data[indextr].delta_tax_value }}<br />
            Discount : {{ data[indextr].delta_discount_value }}<br />
            Total : {{ data[indextr].delta_total_value }}<br />
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";

export default {
  components: {
    Datepicker,
  },
  props: {
    piId: {
      type: Number,
    },
    vendor: {
      type: String,
    },
    poCode: {
      type: String,
    },
    invCode: {
      type: String,
    },
    warehouseCode: {
      type: String,
    },
    warehouseName: {
      type: String,
    },
    createdDate: {
      type: String,
    },
    status: {
      type: String,
    },
  },
  created() {},
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        optionPaymentTerm: [],
        optionBankName: [],
        search: "",
        offset: 0,
        limit: 10,
        status_submit: false,
        table: {
          inv_code: null,
          inv_date: null,
          payment_term: null,
          bank_name: null,
          account_name: null,
          account_number: null,
          total_inv_value: 0,
          item_value: 0,
          charges_value: 0,
          tax_value: 0,
          data: [],
          data_charges: [],
          data_total: [],
        },
        tax_item: [],
        tax_charges: [],
        charges: [],
        net: [],
        total: [],
        taxx: [0, 0],
      };
    },
    approval(id) {
      let param = {
        id: this.piId,
        status: (id == 1) ? "20" : "21"
      };
      this.$http
        .post("/api/v1/pi/change-status", param)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/pi/vendor-invoice-line/" + this.piId)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.tr_date = moment(String(resp.data.tr_date)).format("YYYY-MM-DD") == "0001-01-01" ? "" : moment(String(new Date(resp.data.tr_date))).format("DD/MM/YYYY");
            this.tr_number = resp.data.tr_number;
            this.table.inv_date =
              this.invCode != 0
                ? moment(String(new Date(resp.data.inv_date))).format("DD/MM/YYYY")
                : null;
            this.table.payment_term_name = resp.data.payment_term_name;
            this.table.bank_name = resp.data.bank_name + " - " + resp.data.account_number + " " + resp.data.account_name;

            this.table.po_code = resp.data.po_code;
            this.table.vendor = resp.data.vendor;

            this.table.data_total.push({
                delta_item_value: resp.data.delta_item_value
                .toFixed(2)
                .toString()
                .replace(/,/g, "")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              delta_charge_value: resp.data.delta_charges_value
                .toFixed(2)
                .toString()
                .replace(/,/g, "")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              delta_tax_value: resp.data.delta_tax_value
                .toFixed(2)
                .toString()
                .replace(/,/g, "")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              delta_discount_value: resp.data.delta_discount_value
                .toFixed(2)
                .toString()
                .replace(/,/g, "")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              delta_total_value: resp.data.delta_total_value
                .toFixed(2)
                .toString()
                .replace(/,/g, "")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              discount_value_po: resp.data.discount_value_po
                .toFixed(2)
                .toString()
                .replace(/,/g, "")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              total_po_value: resp.data.total_po_value
                .toFixed(2)
                .toString()
                .replace(/,/g, "")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              item_value_po: resp.data.item_value_po
                .toFixed(2)
                .toString()
                .replace(/,/g, "")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              charges_value_po: resp.data.charge_value_po
                .toFixed(2)
                .toString()
                .replace(/,/g, "")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              tax_value_po: resp.data.tax_value_po
                .toFixed(2)
                .toString()
                .replace(/,/g, "")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              discount_value: resp.data.total_discount
                .toFixed(2)
                .toString()
                .replace(/,/g, "")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              total_inv_value: resp.data.total_invoice_value
                .toFixed(2)
                .toString()
                .replace(/,/g, "")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              item_value: resp.data.item_value
                .toFixed(2)
                .toString()
                .replace(/,/g, "")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              charges_value: resp.data.charge_value
                .toFixed(2)
                .toString()
                .replace(/,/g, "")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              tax_value: resp.data.tax_value
                .toFixed(2)
                .toString()
                .replace(/,/g, "")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            });

            let _this = this;
            resp.data.data_line.forEach(function (element, index) {
              _this.table.data.push({
                net: element.net
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                price: element.price
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                price_po: element.price_po
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                tax: element.tax
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                quantity: element.quantity
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                item_name: element.item_name,
                item_unit: element.item_unit,
                id: element.id,
                amount_uom: element.amount_uom,
                qty_received: element.qty_received
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                qty_po: element.qty_po
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                tax_rate: element.tax_rate,
                discount_po: element.discount
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                discount_inv: element.discount_inv
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                net_po: element.net_po
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                net_inv: element.net_inv
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                
                delta_net: Math.abs(element.net_inv - element.net_po)
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                delta_qty: Math.abs(element.qty_po - element.qty_received)
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                delta_price: Math.abs(element.price_po - element.price)
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                delta_discount: Math.abs(element.discount - element.discount_inv)
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              });
            });

            resp.data.data_charge.forEach(function (element, index) {
              _this.table.data_charges.push({
                value_inv: element.value
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                tax_inv: element.tax
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                value_po: element.value_po
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                tax_po: element.tax_po
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                id: element.id,
                charge_name: element.charge_name,
                tax_rate: element.tax_rate,
                delta_value: Math.abs(element.value - element.value_po)
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                delta_tax: Math.abs(element.tax - element.tax_po)
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              });
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
  },
  mounted() {
    if (this.piId) {
      this.getData();
      this.table.inv_code = this.invCode != 0 ? this.invCode : null;
    }
  },
  computed: {},
  watch: {
    piId() {
      Object.assign(this.$data, this.initialState());
      this.getData();
      this.table.inv_code = this.invCode != 0 ? this.invCode : null;
    },
  },
};
</script>
<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}
</style>